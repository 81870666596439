<template>
  <div class="budesonid">
    <div
        class="game-wrapper"
        :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
      ]"
    >

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text  green">
            <span>Добрий день!<br>У мене рецепт від лікаря. Погляньте, будь ласка</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-budesonid/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Відповісти
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text  blue">
            <span>Мгг… Будесонід 0,25 мг, 2 рази на добу.<br>Так, звісно, одну хвилинку!</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-budesonid/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text  blue">
            <span>Так… робимо вигляд, що чую не вперше та дивлюсь у базі </span></p>

          <div class="game-wrapper__bubble">
            <svg width="12" height="42" viewBox="0 0 12 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" fill="#ffffff"/>
              <circle cx="6" cy="21" r="6" fill="#ffffff"/>
              <circle cx="6" cy="36" r="6" fill="#ffffff"/>
            </svg>
          </div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-budesonid/stage-3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            Знайти препарат
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--white-block stage4">
          <p class="game-wrapper__stage--text  blue">
            <span>Ось він! Якраз маємо в наявності.<br>Зараз Вам принесу</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-budesonid/stage-4-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup custom-p">

          <div class="game-wrapper__stage--top-text">
            <span>Будесонід-Тева</span> — інгаляційний засіб, призначений для лікування бронхіальної астми у пацієнтів,
            для яких застосування інгаляторів із розпиленням лікарських засобів стиснутим повітрям або у вигляді
            лікарської форми сухого порошку є неефективним або недоцільним<sup>1</sup>.
          </div>

          <div class="game-wrapper__stage--bottom-text">
            Цікаво, як ці вдихувані частинки дістаються до потрібного місця, адже дихальні шляхи можуть здатись
            справжнім лабіринтом.
            <span>Пропонуємо Вам зіграти в гру та знайти вихід із цього лабіринту!</span>
          </div>

          <div class="game-wrapper__stage--btn-next-stage hover_type6 auto-m" @click="nextStage(6)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <BudesonidGame v-if="stage === 6"></BudesonidGame>
    </div>

    <div
        class="notes-wrapper"
        v-show="stage !== 6"
        :class="{ active:  stage === 5 }"
    >
      <span v-show="stage === 5">1. Інструкція для медичного застосування лікарського засобу БУДЕСОНІД-ТЕВА, суспензія для розпилення, 0.25 мг/мл. РП №UA/18925/01/01. Наказ МОЗ України №1819 від 27.08.2021.</span>
      <span>Інформація про лікарський засіб. РП №UA/18925/01/01, №UA/18925/01/02. Для професійної діяльності медичних та фармацевтичних працівників.</span>

      <span
      >Будесонід-Тева (1 мл суспензії для розпилення містить будесоніду 0,25 мг або 0,5 мг). Характеристики: суспензія від білого до майже білого кольору в ампулі, що містить разову дозу. Лікувальні властивості: інгаляційний засіб, що застосовуються для лікування обструктивних захворювань дихальних шляхів. Глюкокортикоїд. Найбільш частими побічними реакціями є: орофарингеальний кандидоз, пневмонія (у пацієнтів з ХОЗЛ), кашель, подразнення горла, захриплість, подразнення слизової оболонки ротової порожнини, утруднення ковтання. З повним переліком побічних реакцій можна ознайомитися в інструкції для медичного застосування.
      </span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00823. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <img
        v-if="stage === 1 || stage === 2 || stage === 3 || stage === 4|| stage === 6"
        class="bg"
        src="../../assets/img/game-budesonid/bg.png"
        alt=""
    />

    <img
        v-else
        class="bg"
        src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
        alt=""
    />
  </div>
</template>

<script>
import BudesonidGame from '../../components/Budesonid-game';

export default {
  name: "budesonid",

  components: {
    BudesonidGame
  },

  data: () => ({
    stage: 1,
  }),

  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";


.budesonid {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &__bubble {
      position: absolute;
      bottom: -39px;
    }

    &.stage6 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;



      &--white-block {
        max-width: 610px;
        width: 100%;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;
        height: 110px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: 0;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          height: auto;

          img {
            max-width: 280px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: 55px;

        @media screen and (max-width: 425px) {
          max-width: 100%;
        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;


          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;
          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        span {
          font-family: $EB;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 80px 30px 35px 30px;
        text-align: center;

        font-family: $R;
        font-size: 20px;
        line-height: 120%;
        color: white;
        span{
          font-family: $B;
        }

        sup {
          font-size: 60%;
          line-height: 0;
          vertical-align: top;
        }
      }

      &--bottom-text {
        color: #424242;
        font-size: 20px;
        line-height: 120%;

        font-family: $R;

        padding: 47px 30px 47px 30px;
        text-align: center;

        span {
          display: block;
          font-family: $B;

        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .budesonid {

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &.stage3 {
      }

      &__stage {

        &--white-block {
          height: 90px;
          padding: 10px;


          &.bottom {
            height: 70px;
          }

          &.stage5 {
            height: auto;
          }

          &.popup {
            padding-bottom: 17px;
            height: auto;

            img {
              max-width: 274px;
            }
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {
          padding-left: 10px;
          padding-right: 10px;

          margin-top: 60px;

          &.mr-top {
            margin-top: 0;

            img {
              margin-top: 0;
            }
          }

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 14px;
          line-height: 120%;
          padding: 28px 20px 23px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 21px 20px 28px 20px;
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}
</style>
