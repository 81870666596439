<template>
  <div class="Budesonid-game">
    <div class="game-stage1" v-show="!result.win && !result.lose">
      <div class="game-bar">
        <div class="game-bar__timer-section">
          <svg
            :class="[{ red: time <= 10 }]"
            viewBox="0 0 28 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14"
              cy="18.3333"
              r="13"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2" />
            <path
              d="M14 31.3333L14 28.0833"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14 8.58331L14 5.33331"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M1 18.3333L4.25 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.75 18.3333L27 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M4.80737 9.14093L7.10547 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M20.8943 25.2277L23.1924 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.1926 9.14093L20.8945 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M7.10571 25.2277L4.80762 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14.0003 18.7246L18.6045 10.75"
              stroke="#00A03B"
              stroke-width="2"
            />
          </svg>

          <timer :class="[{ red: time <= 10 }]" :time="prettyTime"></timer>
        </div>
      </div>

      <div class="game">
        <div class="game__wrapper">
          <div ref="mobText" class="game__mob-text">
            Завдання – знайти вихід! Поспішайте, часу обмаль!
          </div>

          <div class="game__wrapper--map">
            <v-map></v-map>

            <div
              style="
                position: absolute;
                top: 0;
                left: calc(min(20px, 6.4%));
                right: calc(min(22px, 6.7%));
                bottom: 0;
              "
            >
              <div class="player" :style="getPosition()">
                <div class="player--body">
                  <div class="line" v-if="time === 0"></div>
                </div>
              </div>

              <!-- <div class="grid">
                <template v-for="(row, i) in comMap">
                  <div
                    class="grid__item"
                    v-for="(col, j) in row"
                    :key="`${i}x${j}`"
                    :style="{
                      top: `calc(100% / 15 * ${i})`,
                      left: `calc(100% / 15 * ${j})`,
                      width: `calc(100% / 15)`,
                      height: `calc(100% / 15)`,
                      borderTop: !col.top ? '2px solid rgba(0,0,0,1)' : null,
                      borderLeft: !col.left ? '2px solid rgba(0,0,0,1)' : null,
                      borderRight: !col.right
                        ? '2px solid rgba(0,0,0,1)'
                        : null,
                      borderBottom: !col.bottom
                        ? '2px solid rgba(0,0,0,1)'
                        : null,
                    }"
                  ></div>
                </template>
              </div> -->
            </div>
          </div>
        </div>

        <div class="game__tip">
          використовуйте кнопки зі стрілками щоб рухатися
        </div>

        <div class="game__input">
          <div>
            <button
              @keyup.up="move('top')"
              @click="move('top')"
              class="game__input--top"
            >
              <svg
                width="37"
                height="23"
                viewBox="0 0 37 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.29199 20.4349L18.3935 4.33339L34.4951 20.4349"
                  stroke="white"
                  stroke-width="6"
                />
              </svg>
            </button>
          </div>

          <div>
            <button @click="move('left')" class="game__input--left">
              <svg
                width="24"
                height="39"
                viewBox="0 0 24 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.6572 36.0636L5.00045 19.4068L21.6572 2.75005"
                  stroke="white"
                  stroke-width="6"
                />
              </svg>
            </button>
            <button @click="move('right')" class="game__input--right">
              <svg
                width="24"
                height="39"
                viewBox="0 0 24 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.15723 2.75L18.814 19.4068L2.15722 36.0635"
                  stroke="white"
                  stroke-width="6"
                />
              </svg>
            </button>
          </div>

          <div>
            <button @click="move('bottom')" class="game__input--bottom">
              <svg
                width="39"
                height="24"
                viewBox="0 0 39 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 2.15674L19.4068 18.8135L36.0635 2.15674"
                  stroke="white"
                  stroke-width="6"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block stage1">
        <p class="win__text desk white">
          <span>Будесонід, 0,25 мг!</span>
          <br />
          Ось тримайте рецепт від Вашого лікаря
        </p>

        <p class="win__text mob white">
          <span>Будесонід, 0,25 мг!</span>
          <br />
          Ось тримайте рецепт <br />
          від Вашого лікаря
        </p>

        <div class="win__arrow-down"></div>
      </div>

      <img
        class="win__img-circle"
        src="../../assets/img/game-budesonid/win.png"
        alt=""
      />

      <div class="win__text-center">
        Фантастика! Ви не розгубитесь у будь-якій ситуації. Вдала робота та
        задоволені клієнти.
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p>
            <span>Будесонід-Тева</span> — лікування бронхіальної астми у
            пацієнтів, для яких застосування інгаляторів з розпиленням
            лікарських засобів стиснутим повітрям або у вигляді лікарської форми
            сухого порошку є неефективним або недоцільним<sup>1</sup>
          </p>
        </div>

        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#35786E" />
            <path
              d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B" />
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B" />
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B" />
          </svg>
        </div>

        <div class="win__popup--text">
          1. Інструкція для медичного застосування лікарського засобу
          БУДЕСОНІД-ТЕВА, суспензія для розпилення, 0.25 мг/мл.
          РП №UA/18925/01/01. Наказ МОЗ України №1819 від 27.08.2021.
        </div>
        <div class="win__popup--text">
          Інформація про лікарський засіб. РП №UA/18925/01/01, №UA/18925/01/02.
          Для професійної діяльності медичних та фармацевтичних працівників.
        </div>
        <div class="win__popup--text">
          Будесонід-Тева (1 мл суспензії для розпилення містить будесоніду 0,25
          мг або 0,5 мг). Характеристики: суспензія від білого до майже білого
          кольору в ампулі, що містить разову дозу. Лікувальні властивості:
          інгаляційний засіб, що застосовуються для лікування обструктивних
          захворювань дихальних шляхів. Глюкокортикоїд. Найбільш частими
          побічними реакціями є: орофарингеальний кандидоз, пневмонія (у
          пацієнтів з ХОЗЛ), кашель, подразнення горла, захриплість, подразнення
          слизової оболонки ротової порожнини, утруднення ковтання. З повним
          переліком побічних реакцій можна ознайомитися в інструкції для
          медичного застосування.
        </div>
        <div class="win__popup--text">
          Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
          поверх 9; 0-800-502-284
        </div>
        <div class="win__popup--text">
          CBG-UA-00823. Термін дії матеріалу – 23.08.2024.
        </div>
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span> Тааак, вийшла справжня плутанина!</span>
          <br />
          Спробуйте ще раз та знайдіть вихід із лабіринту
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
        class="lose__img-circle"
        src="../../assets/img/game-atorvastatin/lose.png"
        alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose" class="notes-wrapper">
      <span
        >Інформація про лікарський засіб. РП №UA/18609/01/01. Для професійної
        діяльності медичних та фармацевтичних працівників.</span
      >

      <span
        >Ацетилцистеїн-Тева (1 таблетка шипуча містить: ацетилцистеїну 600 мг).
        Характеристики: білі або злегка жовтуваті, круглі, пласкі з обох боків,
        шипучі таблетки, без лінії розлому та із запахом лимона. Лікувальні
        властивості: засоби, що застосовуються при кашлі та застудних
        захворюваннях. Муколітичний засіб. Найбільш частими побічними реакціями
        є: головний біль, дзвін у вухах, тахікардія, блювання, діарея, стоматит,
        абдомінальний біль, нудота тощо.</span
      >

      <span
        >Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284. CBG-UA-00895. Термін дії матеріалу –
        10.2024.</span
      >
    </div>
    <div v-show="!result.win && !result.lose" class="notes-wrapper desk">
      <span
        >Інформація про лікарський засіб. РП №UA/18925/01/01, №UA/18925/01/02.
        Для професійної діяльності медичних та фармацевтичних працівників.
      </span>

      <span
        >Будесонід-Тева (1 мл суспензії для розпилення містить будесоніду 0,25
        мг або 0,5 мг). Характеристики: суспензія від білого до майже білого
        кольору в ампулі, що містить разову дозу. Лікувальні властивості:
        інгаляційний засіб, що застосовуються для лікування обструктивних
        захворювань дихальних шляхів. Глюкокортикоїд. Найбільш частими побічними
        реакціями є: орофарингеальний кандидоз, пневмонія (у пацієнтів з ХОЗЛ),
        кашель, подразнення горла, захриплість, подразнення слизової оболонки
        ротової порожнини, утруднення ковтання. З повним переліком побічних
        реакцій можна ознайомитися в інструкції для медичного застосування.
      </span>

      <span
        >Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284</span
      >
      <span>CBG-UA-00823. Термін дії матеріалу – 23.08.2024.</span>
    </div>
  </div>
</template>

<script>
import Timer from "../Timer.vue";
import VMap from "./map.vue";

document.ontouchmove = function (event) {
  event.preventDefault();
};

export default {
  name: "Acetylcysteine-game",

  components: {
    Timer,
    VMap,
  },

  data: () => ({
    minutes: 0,
    secondes: 0,
    time: 60,
    timer: null,

    error: false,
    winPopup: false,

    result: {
      win: false,
      lose: false,
    },

    map: [
      [
        "rb",
        "lr",
        "lrb",
        "lr",
        "lr",
        "l",
        "b",
        "b",
        "rb",
        "lb",
        "b",
        "r",
        "lb",
        "rb",
        "lb",
      ],
      [
        "tb",
        "r",
        "tl",
        "rb",
        "lrb",
        "lr",
        "tlb",
        "trb",
        "tl",
        "tb",
        "tb",
        "rb",
        "tl",
        "tb",
        "tb",
      ],
      [
        "trb",
        "lb",
        "rb",
        "tlb",
        "tb",
        "b",
        "trb",
        "tl",
        "rb",
        "tlr",
        "tlrb",
        "tlr",
        "l",
        "tb",
        "tb",
      ],
      [
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tr",
        "tlr",
        "l",
        "tb",
        "b",
        "tr",
        "lr",
        "lr",
        "tl",
        "t",
      ],
      [
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "b",
        "b",
        "b",
        "tb",
        "tr",
        "lb",
        "rb",
        "lrb",
        "lr",
        "l",
      ],
      [
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tr",
        "tlrb",
        "lrb",
        "tlb",
        "tb",
        "tb",
        "rb",
        "lb",
      ],
      [
        "tb",
        "tr",
        "tl",
        "t",
        "tb",
        "tb",
        "tb",
        "rb",
        "tl",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
      ],
      [
        "tlrb",
        "lb",
        "",
        "rb",
        "tl",
        "tb",
        "tb",
        "tb",
        "b",
        "tb",
        "tr",
        "tl",
        "tb",
        "tb",
        "trb",
      ],
      [
        "tb",
        "tb",
        "",
        "tb",
        "b",
        "tr",
        "tlr",
        "tlb",
        "tb",
        "tr",
        "rl",
        "rl",
        "trlb",
        "tlb",
        "t",
      ],
      [
        "tb",
        "tb",
        "",
        "tb",
        "tr",
        "rlb",
        "rl",
        "tlb",
        "trb",
        "lr",
        "lr",
        "lb",
        "tb",
        "tr",
        "l",
      ],
      [
        "tb",
        "t",
        "b",
        "tr",
        "lb",
        "t",
        "b",
        "tb",
        "tb",
        "rb",
        "lb",
        "tb",
        "tr",
        "lr",
        "lb",
      ],
      [
        "trb",
        "lr",
        "tlrb",
        "l",
        "tr",
        "lb",
        "tb",
        "tb",
        "t",
        "tb",
        "tb",
        "tb",
        "rb",
        "lb",
        "tb",
      ],
      [
        "tb",
        "b",
        "tb",
        "rb",
        "lb",
        "t",
        "tb",
        "tr",
        "lb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
      ],
      [
        "tb",
        "tb",
        "t",
        "tb",
        "t",
        "r",
        "tlr",
        "lr",
        "tl",
        "t",
        "tb",
        "tb",
        "tb",
        "tb",
        "tb",
      ],
      [
        "t",
        "tr",
        "l",
        "tr",
        "lr",
        "lr",
        "lr",
        "lr",
        "l",
        "r",
        "tlr",
        "tlr",
        "tl",
        "tr",
        "tl",
      ],
    ],

    player: {
      x: -1,
      y: 7,
      top: false,
      left: false,
      right: true,
      bottom: false,
    },
  }),

  watch: {
    time: function (val) {
      if (val === 0) {
        this.onSubmit();
      }
    },
  },

  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },

    comMap() {
      return this.map.map((row, i) => {
        return row.map((col, j) => {
          if (!col) return {};

          const arr = col.split("");
          return {
            top: arr.includes("t"),
            left: arr.includes("l"),
            right: arr.includes("r"),
            bottom: arr.includes("b"),
          };
        });
      });
    },
  },

  mounted() {
    this.start();
    // start timer
  },

  methods: {
    start() {
      if (this.timer) clearInterval(this.timer);

      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.timer);
          // this.reset()
        }
      }, 1000);
    },

    reset() {
      this.time = 60;
      this.timer = null;
      this.result.lose = false;
      this.result.win = false;
      this.error = false;
      window.scrollTo(0, 0);

      this.player = {
        x: -1,
        y: 7,
        top: false,
        left: false,
        right: true,
        bottom: false,
      };

      this.start();
    },

    getPosition() {
      let { x, y } = this.player;

      if (x === -1) x -= 1;
      // if (x > 14) x += 1;

      return {
        top: `calc((100% / 15) * ${y}`,
        left: `calc((min(100%, 320px) / 15) * ${x})`,
      };
    },

    isWin() {
      let { x, y } = this.player;
      return y === 7 && x === 15;
    },

    move(direction) {
      if (!this.player[direction]) return;

      switch (direction) {
        case "top":
          this.player.y -= 1;
          break;
        case "left":
          this.player.x -= 1;
          break;
        case "right":
          this.player.x += 1;
          break;
        case "bottom":
          this.player.y += 1;
          break;
      }

      let nextStep = this.comMap[this.player.y][this.player.x];
      if (!nextStep) {
        nextStep = {
          top: false,
          left: false, // this.player.x > 14,
          right: this.player.x < 0,
          bottom: false,
        };
      }

      this.player = {
        ...this.player,
        ...nextStep,
      };

      if (this.isWin()) {
        this.onSubmit();
      }
    },

    onSubmit() {
      setTimeout(() => {
        this.result[this.isWin() ? "win" : "lose"] = true;
        window.scrollTo(0, 0);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.Budesonid-game {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  svg {
    vertical-align: bottom;
  }

  .timer {
    font-family: $B;
  }

  .game-bar {
    display: flex;
    justify-content: space-between;

    max-width: 650px;
    padding: 0 20px;
    margin: 0 auto;

    &__timer-section {
      margin: auto;
      display: flex;
      align-items: center;

      svg {
        max-width: 48px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }
  }

  .game {
    margin: 10px auto;
    max-width: 610px;
    padding: 0 20px;

    &__mob-text {
      display: none;
    }

    &__wrapper {
      padding: 10px 50px;
      width: 100%;
      text-align: center;
      background-color: #00a03b;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;

      &--map {
        position: relative;
        margin: auto;

        max-width: 320px;

        .player {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% / 15);
          height: calc(100% / 15);
          border-radius: 50%;

          box-shadow: 0 0 0 18px rgba(255, 255, 255, 0.2),
            0 0 0 9px rgba(255, 255, 255, 0.2);

          &--body {
            width: 100%;
            height: 100%;
            position: relative;

            .line {
              width: 100%;
              height: 2px;

              background: white;
              z-index: 10;
              position: absolute;

              transform: translate(-50%, -50%) rotate(-45deg);
              top: 50%;
              left: 50%;
            }

            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #c40000;
              box-shadow: 0 0 0 2px #fff;
            }

            &::before {
              margin-left: 20%;
            }
          }
        }

        svg {
          width: 100%;
          height: 100%;
        }

        .grid {
          &__item {
            position: absolute;
          }
        }
      }
    }

    &__input {
      margin: 15px auto;
      width: 220px;
      text-align: center;
      user-select: none;
      touch-action: manipulation;

      button {
        touch-action: manipulation;
        user-select: none;
        cursor: pointer;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 58px;
        height: 58px;
        border: none;
        border-radius: 50%;
        background: #00a03b;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);

        svg {
          display: block;
          position: relative;
        }
      }

      &--top {
        svg {
          top: -3px;
        }
      }

      &--bottom {
        svg {
          bottom: -3px;
        }
      }

      &--left {
        margin: -21px 52px -21px 0;

        svg {
          left: -3px;
        }
      }

      &--right {
        margin: -21px 0 -21px 52px;

        svg {
          right: -3px;
        }
      }
    }

    &__tip {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 10px auto 10px;
      font-family: $R;
      font-size: 14px;
      line-height: 120%;
      text-align: center;

      color: $grey;
      opacity: 0.4;

      &.mob {
        display: none;
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 20px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 440px;
      width: 100%;
      height: auto;
      display: block;
      margin: 6% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -3% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    position: relative;
    padding: 0 30px 0 30px;
    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $blue;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 30px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $blue;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 35px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      color: $green;
      font-size: 24px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-height: 594px) {
  .Budesonid-game {
    .game {
      &__action {
        .blood {
          .item {
            .limpha {
              width: 32px;
            }

            .protect {
              width: 60px;
            }

            &.active {
              transform: translateX(110px);
              transition: transform 6s linear;
            }

            &.hide {
              display: none;
            }
          }
        }

        .hit-zone {
          .green {
            max-width: 52px !important;
          }

          .red {
            max-width: 100px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Budesonid-game {
    padding-top: 0;

    .lose {
      padding-top: 20px;
      padding-bottom: 0;

      &__red-block {
        max-width: calc(100%);
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;
        margin-top: -7%;
      }

      &__repeat {
        margin: -5% auto 0 auto;

        p {
        }
      }
    }

    .win {
      padding-top: 20px;
      padding-bottom: 0;
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 20px);

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 43px 20px;
        bottom: -10px;
      }

      &__popup--close-btn {
        margin-bottom: 14px;

        svg {
        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }

    .game-bar {
      margin-top: 13px;

      &__timer-section {
        svg {
          max-width: 48px;
        }
      }

      &__health-wrapper {
        svg {
          &:last-child {
          }

          path {
          }
        }
      }
    }

    .game {
      &__tip {
        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }
      }

      &__mob-text {
        display: block;
        color: white;
        font-family: $B;
        font-size: 14px;
        line-height: 120%;
        padding-bottom: 14px;
      }

      &__action {
        height: calc(
          (var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) -
            80px
        );

        .hit-zone {
          svg {
          }
        }

        .slider {
        }

        .top-img {
        }

        .blood {
          position: absolute;
          bottom: 83px;
          width: 100%;
          display: flex;

          .item {
            &.active {
            }

            &.hide {
              display: none;
            }
          }
        }
      }

      &__btn-wrapper {
      }

      &__grey-text {
        font-size: 14px;
        line-height: 120%;
      }

      &__btn {
        max-width: 230px;
        width: 100%;
        margin: 20px auto 0 auto;

        p {
          font-size: 24px;
          line-height: 26px;

          padding: 10px;

          span {
          }
        }
      }
    }
  }
}
</style>
